/**
 * Chando Bootstrap Variable Overrides Here
 */

// Colors
$orange: #ff6b38;
$primary: $orange;
$chando-bg: #2a2829;

// Color sets
$body-bg: $chando-bg;
$body-color: #000;

// Fonts
$font-size-base: 1.25rem;
$font-family-base: "Roboto", serif;
$headings-font-family: 'Marcellus', serif;

