// Declare Chando Variable Overrides First
@import "variables";

// Load your external fonts
// Google Fonts
// Combined all required Google Fonts into one call to reduce load time
@import url('//fonts.googleapis.com/css?family=Roboto|Marcellus&display=swap');


// Then load Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Then do all your other stuff here

/**
 * Examples
 * Class names start with .
 * ID names start with #
 */


.title {
  text-align: center;
  color: $white
}

header {
  background: $chando-bg;
  text-align: center;
  border-bottom: 5px solid lighten($chando-bg, 16%);
  padding: 40px 0;
  margin: 0 0 1px 0;
  color: $white;

  // Example of nested elements
  h1 {
    // Something
  }

}

main {
  background: rgb(224, 224, 219);
  padding: 60px 0;
}

footer {
  padding: 20px 0;
  font-weight: bold;
  color: $white;
  text-align: center;
}
